import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Modal,
  notification,
  Checkbox,
} from 'antd';
import { CheckOutlined, DeleteOutlined, LoginOutlined } from '@ant-design/icons';
import { ButtonsBar } from 'Components';
import { User } from 'GraphQL/queries/user.graphql';
import { useCreateUserMutation, Role } from 'GraphQL/mutations/createUser.graphql';
import { useResetSecondFactorMutation, useUpdateUserMutation } from 'GraphQL/mutations/updateUser.graphql';
import { useDeleteUserMutation } from 'GraphQL/mutations/deleteUser.graphql';
import { useLoginAsMutation } from 'GraphQL/mutations/loginAs.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import useAuth from 'Hooks/useAuth';

interface UsersFormProps {
  user?: Omit<User, 'createdAt' | 'updatedAt'> | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  firstName: string
  lastNamePrefix: string
  lastName: string
  email: string
  password: string
  role: Role
}

const UsersForm: FC<UsersFormProps> = ({ user, onSubmit, onDelete }: UsersFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const role = Form.useWatch('role', form);
  const { isAdmin } = useAuth();

  const [createUserMutation, { loading: loadingCreate }] = useCreateUserMutation({ ...invalidateCache('users') });
  const [updateUserMutation, { loading: loadingUpdate }] = useUpdateUserMutation();
  const [resetSecondFactorMutation] = useResetSecondFactorMutation();
  const [deleteUserMutation] = useDeleteUserMutation({ ...invalidateCache('users') });
  const [loginAsMutation] = useLoginAsMutation();

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createUserMutation({ variables: { data: formData } });
      notification.success({ message: 'Gebruiker is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Gebruiker toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateUserMutation({
        variables: {
          data: formData,
          id: user!.id,
        },
      });
      notification.success({ message: 'Gebruiker is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Gebruiker opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteUserMutation({ variables: { id: user!.id } });
      notification.success({ message: 'Gebruiker is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Gebruiker verwijderen mislukt' });
    }
  };

  const handleLogin = async () => {
    const { data } = await loginAsMutation({ variables: { email: user!.email } });
    if (data?.session) {
      const host = window.location.host.startsWith('localhost') ? 'localhost:4001' : `klanten.${window.location.host}`;
      window.open(`${window.location.protocol}//${host}/#/?signIn=${data.session.token}`);
    }
  };

  const handleResetSecondFactor = async () => {
    try {
      await resetSecondFactorMutation({ variables: { userId: user!.id } });
      notification.success({ message: 'Twee factor authenticatie is gereset' });
    } catch (e) {
      notification.error({ message: 'Twee factor authenticatie resetten is mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [user]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={user || undefined}
      onFinish={user ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />
      <Row gutter={10}>
        <Col flex="240px">
          <Form.Item
            label="Voornaam "
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex="120px">
          <Form.Item
            label="Tussenvoegsel"
            name="lastNamePrefix"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex="240px">
          <Form.Item label="Achternaam" name="lastName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Email"
        name="email"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Wachtwoord"
        name="password"
        rules={[{ required: !user }]}
        tooltip={user ? 'Alleen invullen als je het bestaande wachtwoord wilt wijzigen' : undefined}
      >
        <Input.Password
          autoComplete="new-password"
        />
      </Form.Item>

      <Row gutter={10}>
        <Col flex="360px">
          <Form.Item
            label="Rol"
            name="role"
            rules={[{ required: true }]}
          >
            <Select placeholder="Rol">
              <Select.Option value={Role.Admin}>Admin</Select.Option>
              <Select.Option value={Role.NewsEditor}>Nieuws Editor</Select.Option>
              <Select.Option value={Role.TermsEditor}>Begrippen Editor</Select.Option>
              <Select.Option value={Role.Reviewer}>Reviewer</Select.Option>
              <Select.Option value={Role.CompanyAdmin}>Beheerder</Select.Option>
              <Select.Option value={Role.User}>Medewerker</Select.Option>
              <Select.Option value={Role.Auditor}>Auditor</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {user && [Role.CompanyAdmin, Role.User, Role.Auditor].includes(role) && (
          <Col>
            <Button type="link" icon={<LoginOutlined />} style={{ marginTop: 23 }} onClick={handleLogin}>
              Inloggen op klantenportaal
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={10} align="middle" style={{ marginTop: 10 }}>
        <Col>
          <Checkbox checked={user?.secondFactorEnabled} disabled>
            Twee factor authenticatie
          </Checkbox>
        </Col>
        <Col>
          <Button onClick={handleResetSecondFactor} disabled={!user?.secondFactorEnabled}>
            Reset
          </Button>
        </Col>
      </Row>

      {isAdmin && (
        <ButtonsBar>
          {user && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                okText="Ik weet het zeker"
                cancelText="Annuleren"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u ${user.firstName} ${user.lastNamePrefix || ''} ${user.lastName} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}
    </Form>
  );
};

export default UsersForm;
